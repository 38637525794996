<template lang="pug" src="./Dashboard.pug">
</template>
<script>

  import Vue from 'vue'
  import { formatPrice } from "../utils/formatPrice.js"
  Vue.prototype.formatPrice = formatPrice

  import moment from 'moment-timezone'
  import { mapGetters, mapActions } from 'vuex'

  export default{
    name: 'Painel',

    data: () => ({
      code: { code: 'Todos os códigos', value: '000' },
      itemCopied: false,
      explainConversions: false,
      chartOptions: {
        chart: {
          id: 'faturamento',
          type: 'bar',
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: { columnWidth: '80%' }
        },
        yaxis: { show: false },
        grid: {
          show: true,
          borderColor: '#eee',
          strokeDashArray: 1,
          position: 'back',
          xaxis: {
            lines: { show: false }
          },
          yaxis: {
            lines: {
              show: true,
              offsetX: 0,
              offsetY: 0
            },
          }
        }
      },
      series: []

    }),

    computed: {
      ...mapGetters(['dashData'])
    },
    methods: {
      ...mapActions(['getDash']),
      copyLink() {

        let str = 'https://meu.ifraldas.com.br/promo/'+this.dashData.promocodes[0].code
        var self = this
        navigator.clipboard.writeText(str).then(function() {
          self.itemCopied = true
        }, function(err) {
          console.error('Erro ao copiar o link: ', err);
        })

          // document.getElementById('userCodeDiv').style.display = "block"
          //
          // var copyText = document.getElementById('userCode')
          // copyText.select()
          // copyText.setSelectionRange(0, 99999) /* For mobile devices */
          // document.execCommand("copy")
          //
          // document.getElementById('userCodeDiv').style.display = "none"
          //
      },
      share() {
        let address = this.dashData.promocodes[0].code
        let text = 'Ol%C3%A1!%20Voc%C3%AA%20j%C3%A1%20conhece%20a%20iFraldas%3F%20%0ACrie%20a%20lista%20para%20o%20seu%20ch%C3%A1%20de%20beb%C3%AA%20e%20receba%20os%20presentes%20em%20dinheiro.%0AFa%C3%A7a%20o%20seu%20cadastro%20atrav%C3%A9s%20deste%20link%20e%20ganhe%20todos%20os%20recursos%20premium!'

        let href = 'https://api.whatsapp.com/send?text='+text+'%20https://https://meu.ifraldas.com.br/promo/'+address
        window.open(href, '_blank');
      },
      moment: function () {
          return moment()
      },
      updateChart(){
        // monta o gráfico
        this.chartOptions = {...this.chartOptions, ...{
          xaxis: {
              categories: this.dashData.totalCommissionsByMonth.map(({ month }) => moment(month).format('MM/YYYY'))
          }
        }}

        this.$refs.dashChart.updateOptions({
          series: [
            {
              name: 'Recebido',
              data: this.dashData.totalCommissionsByMonth.map(({ total }) => parseFloat(total.toFixed(2)))
            }
          ]
        })

      }
    },
    mounted(){
      this.getDash()
    },
    watch: {
      dashData() {
        this.updateChart()
      }
    }
  }
</script>
